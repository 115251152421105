@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800;900&display=swap");

ul {
    list-style: none;
}
a {
    color: black;
    text-decoration: none;
    &.activated {
        color: red;
    }
}
.hp-phonenumber--wrapper {
    .hp-phonenumber {
        &.has-icon {
            background: url(assets/images/call-icon.png) no-repeat left top;
            padding-left: 35px;
            white-space: nowrap;
        }
        a {
            color: #fff;
            font-family: "Mulish", sans-serif;
            -webkit-transition: all 0.4s ease-in-out;
            -moz-transition: all 0.4s ease-in-out;
            -o-transition: all 0.4s ease-in-out;
            transition: all 0.4s ease-in-out;
        }
    }
}

/**** navigation css ****/
.btn {
    display: none;
    padding: 5px 0;
    color: #000;
    font-size: 18px;
    background: none;
    border: none;
    color: #fff;
    margin-top: -5px;
}

.drawerWrapper {
    padding: 20px;
    .closeButton {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: flex-end;
    }
    .drawerContent {
        h2 {
            font-weight: 700;
            font-size: 20px;
            line-height: 25px;
            color: #000;
            margin: 0;
            padding: 0 0 15px 0;
        }
        p {
            font-weight: 400;
            font-size: 0.9rem;
            line-height: 1.5;
            color: #000;
            margin: 0;
            padding: 0;
        }
        img {
            max-width: 450px;
            padding: 10px;
        }
    }
}

.conferencesContent {
    padding-left: 15px;
    h1 {
        &.conferencesHeading {
            margin: 30px 0 0 0;
            padding: 0 0 30px 0;
        }
    }
}

.accomodationWrapper {
    margin: 0;
    padding: 100px 0 50px 0;
    position: relative;
    h1 {
        font-weight: 700;
        font-size: 32px;
        line-height: 50px;
        text-transform: uppercase;
        color: #000;
        margin: 0;
        padding: 0;
    }
    h2 {
        font-size: 24px;
        font-weight: 500;
        line-height: 30px;
        margin: 0;
        padding-top: 10px;
        letter-spacing: normal;
    }
    h3 {
        font-weight: 500;
        font-size: 21px;
        line-height: 26px;
        color: #000;
        margin: 0;
        padding: 10px 0;
        span {
            color: #606;
            padding-left: 5px;
            font-weight: 700;
        }
    }
    h4 {
        margin: 0;
        font-size: 21px;
        font-weight: 500;
        line-height: 26px;
    }
    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        color: #000;
        margin: 0;
        padding: 0 0 1rem 0;
    }
    figure.image.image-style-side {
        float: right;
    }
}
.notFoundWrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    .notFountData {
        padding: 50px 15px;
        width: 100%;
        max-width: 600px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        .notFoundImg {
            width: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
            img {
                max-width: 400px;
                width: 100%;
            }
        }
        h3 {
            text-align: center;
            padding: 60px 0 10px 0;
            font-weight: 700;
            font-size: 32px;
            line-height: 50px;
            text-transform: uppercase;
            color: #000;
        }
        p {
            margin: 0;
            padding: 0 0 20px 0;
            font-weight: 400;
            font-size: 18px;
            line-height: 36px;
            text-align: center;
            text-transform: capitalize;
            color: #000;
        }
    }
}
.adsSlideshowItem {
    min-height: 194px;
    background-size: cover;
}
.advertisementImage {
    min-height: 194px;
    background-size: cover;
    background-position: center center;
}
.accomodationTopHead {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    .backButton {
        display: inline-flex;
        align-items: center;
        flex-wrap: wrap;
        button {
            border-radius: 50px;
            border: 1px solid #580058;
            padding: 11px 22px;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            text-transform: uppercase;
            color: #fff;
            font-family: "Mulish", sans-serif;
        }
    }
}

@media screen and (max-width: 520px) {
    .accomodationTopHead {
        flex-direction: column-reverse;
    }
    .accomodationTopHead h1 {
        padding-top: 10px;
    }
    .accomodationTopHead h1,
    .accomodationTopHead .backButton {
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .list {
        flex-direction: column;
        height: auto;
        position: absolute;
        right: 0;
        width: auto;
        display: flex;
        align-items: center;
        background: #606;
        top: 100%;
        margin: 0;
        padding: 0;
        min-width: 280px;
        li {
            padding: 10px 0;
            border-bottom: 1px solid #960d96;
            width: 100%;
            text-align: center;
            margin: 0 !important;
            &:last-child {
                border: none;
            }
        }
    }
    .items:nth-child(1) {
        margin-top: 50px;
    }
    .items {
        width: 100%;
        text-align: center;
        margin-right: 0px;
        padding: 20px 0;
    }
    .btn {
        display: block;
    }
    .joinOurTeamWrapper {
        padding: 0 !important;
    }
    .privacyPolicyWrapper {
        padding-bottom: 0 !important;
    }
    .accomodationWrapper {
        padding: 50px 0 20px 0;
    }
    .notFoundWrapper .notFountData {
        padding: 50px 15px 0 15px;
    }
    .notFoundWrapper .notFountData .notFoundImg img {
        max-width: 170px;
    }
    .notFoundWrapper .notFountData h3 {
        font-size: 24px;
    }
    .notFoundWrapper .notFountData p {
        padding: 0;
        font-size: 16px;
        line-height: 24px;
    }
}

/**** header css ****/
.topBar {
    height: 50px;
}
header {
    background: rgba(0, 0, 0, 0.4);
    &.topHeader {
        position: absolute;
        width: 100%;
        min-height: 84px;
        z-index: 1;
        &.activeRoute {
            position: relative;
            background: #030404;
        }
    }
    .headerInner {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: space-between;
        max-height: 84px;
        position: relative;
        .logo {
            display: inline-flex;
            align-items: center;
            flex-wrap: wrap;
            margin-top: -40px;
            max-width: 176px;
            width: 100%;
            img {
                max-width: 100%;
            }
            a {
                display: block;
                width: 100%;
                img {
                    border: none;
                }
            }
        }
        .navigation {
            display: inline-flex;
            align-items: center;
            flex-wrap: wrap;
            padding-top: 35px;
            ul {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                margin: 0;
                padding: 0;
                li {
                    margin: 0 20px;
                    font-size: 20px;
                    line-height: 25px;
                    font-family: "Mulish", sans-serif;
                    font-weight: 600;
                    text-transform: uppercase;
                    a {
                        color: #fff;
                        padding: 5px 0;
                        &:hover {
                            border-bottom: 3px solid rgb(78 156 166);
                        }
                        &.activated {
                            border-bottom: 3px solid rgb(78 156 166);
                        }
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

/**** topbar CSS ****/
ul {
    &.topbarLinks {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: flex-end;
        li {
            display: inline-flex;
            align-items: center;
            flex-wrap: wrap;
            font-family: "Mulish", sans-serif;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            text-transform: uppercase;
            color: #fffdfd;
            a {
                color: #fffdfd;
                line-height: 12px;
            }
            &.socialLinks {
                a {
                    margin-right: 10px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
        span {
            &.seprator {
                width: 1px;
                height: 16px;
                background: #fff;
                margin: 0 20px 0 15px;
            }
        }
    }
}

/**** footer css ****/
footer {
    background: #050606;
    padding: 60px 0 20px 0;
    img {
        &.footerLogo {
            max-width: 220px;
        }
    }
    .quickLinks {
        h3 {
            font-family: "Mulish", sans-serif;
            font-weight: 600;
            font-size: 20px;
            line-height: 50px;
            margin: 0;
            padding: 0 0 10px 0;
            color: #fff;
            text-transform: uppercase;
        }
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                font-family: "Mulish", sans-serif;
                font-weight: 400;
                font-size: 16px;
                line-height: 27px;
                color: #fff;
                a {
                    color: #fff;
                    -webkit-transition: all 0.4s ease-in-out;
                    -moz-transition: all 0.4s ease-in-out;
                    -o-transition: all 0.4s ease-in-out;
                    transition: all 0.4s ease-in-out;
                    &:hover {
                        color: #aa09aa;
                    }
                }
            }
        }
    }
    .newsletter {
        max-width: 320px;
        width: 100%;
        h2 {
            font-family: "Mulish", sans-serif;
            font-weight: 600;
            font-size: 20px;
            line-height: 50px;
            margin: 0;
            padding: 0 0 10px 0;
            color: #fff;
            text-transform: uppercase;
        }
        .newsletterFeild {
            input {
                background: #c4c4c4;
                width: 100%;
                border: none;
                font-family: "Mulish", sans-serif !important;
                font-weight: 400;
                font-size: 14px;
                line-height: 50px;
                color: #727272;
                border-radius: 0;
            }
        }
        .formButton {
            width: 100%;
            font-family: "Mulish", sans-serif;
            font-weight: 700;
            font-size: 16px;
            line-height: 35px;
            margin-top: 8px;
            text-transform: uppercase;
            color: #fff;
            border-radius: 0;
            background: #660066;
        }
    }
    .getItTouch {
        h3 {
            font-family: "Mulish", sans-serif;
            font-weight: 600;
            font-size: 20px;
            line-height: 50px;
            margin: 0;
            padding: 0 0 10px 0;
            color: #fff;
            text-transform: uppercase;
        }
        address {
            font-style: normal;
            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                li {
                    display: flex;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    color: #fff;
                    padding: 0 0 15px 0;
                    span {
                        width: 35px;
                        &.icon {
                            display: inline-flex;
                            align-items: flex-start;
                            flex-wrap: wrap;
                        }
                    }
                    div {
                        width: calc(100% - 35px);
                        display: inline-flex;
                        align-items: flex-start;
                        flex-wrap: wrap;
                        margin: 0;
                        a {
                            color: #fff;
                            font-family: "Mulish", sans-serif;
                            -webkit-transition: all 0.4s ease-in-out;
                            -moz-transition: all 0.4s ease-in-out;
                            -o-transition: all 0.4s ease-in-out;
                            transition: all 0.4s ease-in-out;
                            &:hover {
                                color: #aa09aa;
                            }
                        }
                    }
                    h4 {
                        margin: 0;
                        padding: 0;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 16px;
                        display: block;
                    }
                    p {
                        font-family: "Mulish", sans-serif;
                        margin-bottom: 0;
                    }
                    .hp-phonenumber--wrapper {
                        .hp-phonenumber {
                            &.has-icon {
                                a {
                                    &:hover {
                                        color: #aa09aa;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    hr {
        margin: 20px 0;
        height: 1px;
        background: #333;
        border: none;
    }
    ul {
        &.footerLinks {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            li {
                display: inline-flex;
                align-items: center;
                flex-wrap: wrap;
                position: relative;
                color: #fff;
                font-family: "Mulish", sans-serif;
                font-weight: 300;
                font-size: 16px;
                line-height: 27px;
                padding: 0 20px 0 0;
                a {
                    color: #fff;
                    -webkit-transition: all 0.4s ease-in-out;
                    -moz-transition: all 0.4s ease-in-out;
                    -o-transition: all 0.4s ease-in-out;
                    transition: all 0.4s ease-in-out;
                    &:hover {
                        color: #aa09aa;
                    }
                }
                &:after {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    background: #fff;
                    width: 1px;
                    height: 15px;
                    content: "";
                }
                &:last-child {
                    padding-left: 15px;
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
    ul {
        &.socialLinks {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;
            li {
                display: inline-flex;
                align-items: center;
                flex-wrap: wrap;
                padding-right: 10px;
                a {
                    img {
                        max-width: 24px;
                    }
                }
                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}

.contactWrapper {
    img {
        &.contactImg {
            max-width: 100%;
        }
    }
    h1 {
        font-weight: 700;
        font-size: 32px;
        line-height: 50px;
        text-transform: uppercase;
        color: #000;
        margin: 0;
        padding: 45px 0 0 0;
    }
    .contactformSelect {
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        color: #804b80;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        span {
            display: inline-flex;
            align-items: center;
            flex-wrap: wrap;
            &.text {
                padding-top: 7px;
                @media (max-width: 899px) {
                    margin-bottom: 2rem;
                }
            }
            &.selectWrapper {
                width: 372px;
                margin-left: 33px;
                .selectMenu {
                    margin-top: 0;
                }
            }
        }
    }
    .contactFormWraper {
        padding-left: 15px;
        padding-right: 50px;
        textarea {
            &#message {
                border-radius: 4px;
                position: relative;
                background-color: #fff;
                border: 1px solid #dfdfdf;
                font-size: 16px;
                width: auto;
                height: 100px !important;
                padding: 10px 12px;
                -webkit-transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                    background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                    background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                font-family: "Mulish", sans-serif !important;
                resize: none;
                margin-top: 24px;
                &:focus {
                    outline: none;
                    border: 1px solid #e8e8e8;
                    box-shadow: 0px 4px 20px rgb(128 75 128 / 20%);
                }
            }
        }
        button {
            border-radius: 50px;
            font-weight: 700;
            font-size: 20px;
            line-height: 25px;
            text-transform: uppercase;
            padding: 10px 40px;
        }
        .contactFields {
            margin: 10px 0 0 0;
        }
        label {
            &.inputLabel {
                padding-top: 0 !important;
                font-family: "Mulish", sans-serif !important;
                font-weight: 400 !important;
                font-size: 16px !important;
                text-transform: capitalize !important;
                color: #919191 !important;
            }
        }
    }
    .ruleQuestion {
        margin: 0;
        padding: 45px 0 0 0;
        h3 {
            font-weight: 700;
            font-size: 24px;
            line-height: 50px;
            text-transform: uppercase;
            color: #090909;
            margin: 0;
            padding: 0 0 8px 0;
        }
        button {
            background: #580058;
            border: 1px solid #ffffff;
            border-radius: 50px;
            font-weight: 700;
            font-size: 20px;
            line-height: 25px;
            text-transform: uppercase;
            color: #fff;
            padding: 10px 40px;
            border: none;
            &:hover {
                background: #580058;
            }
        }
    }
    .contactRight {
        margin: 0;
        padding: 30px 30px 0 0;
        address {
            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                li {
                    display: flex;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    color: #fff;
                    padding: 0 0 15px 0;
                    span {
                        width: 35px;
                        &.icon {
                            display: inline-flex;
                            align-items: flex-start;
                            flex-wrap: wrap;
                            color: #660066;
                        }
                    }
                    div {
                        width: calc(100% - 35px);
                        display: inline-flex;
                        align-items: flex-start;
                        flex-wrap: wrap;
                        margin: 0;
                        a {
                            color: #fff;
                            font-family: "Mulish", sans-serif;
                            &:hover {
                                color: #aa09aa;
                            }
                        }
                    }
                    h4 {
                        margin: 0;
                        padding: 0 0 8px 0;
                        font-weight: 700;
                        font-size: 18px;
                        display: block;
                        color: #0c0c0c;
                        font-style: normal;
                    }
                    p {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        text-transform: capitalize;
                        color: #919191;
                        font-style: normal;
                        margin: 0;
                        padding: 0;
                    }
                    .hp-phonenumber--wrapper {
                        .hp-phonenumber {
                            a {
                                font-weight: 700;
                                font-size: 18px;
                                line-height: 50px;
                                text-transform: capitalize;
                                color: #0c0c0c;
                                font-style: normal;
                                &:hover {
                                    color: #580058;
                                }
                            }
                            &.has-icon {
                                background: url(assets/images/phone-icon.png) no-repeat left center;
                            }
                        }
                        &.hasFaxNumber {
                            display: block !important;
                            .hp-phonenumber {
                                &.fax-has-icon {
                                    background: url(assets/images/fax-icon.png) no-repeat left center;
                                    padding-left: 35px;
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.hasFaxNumber {
    display: none !important;
}

.customLabel {
    color: #919191 !important;
    transform: translate(12px, 10px) !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    &.Mui-focused {
        transform: translate(0, -25px) scale(0.75) !important;
    }
    &.MuiInputLabel-shrink {
        transform: translate(0, -25px) scale(0.75) !important;
    }
}

@media only screen and (min-width: 400px) and (max-width: 899px) {
    footer ul.socialLinks {
        justify-content: flex-end;
    }
    footer ul.footerLinks {
        margin: -15px 0 0 0;
    }
    footer ul.socialLinks {
        margin: -15px 0 0 0;
    }
    .footerLogo {
        max-width: 150px;
    }
    footer .newsletter h2 {
        padding: 0 0 20px 0;
        line-height: 28px;
    }
    .joinOurTeamWrapper {
        padding: 0 !important;
    }
    .privacyPolicyWrapper {
        padding-bottom: 0 !important;
    }
}

/**** media queries ****/
@media only screen and (min-width: 0px) and (max-width: 575px) {
    header .headerInner {
        max-height: 66px;
    }
    header .headerInner .logo {
        max-width: 130px;
    }
    header .headerInner .navigation {
        padding-top: 21px;
    }
    .accomodationWrapper h1 {
        font-size: 27px;
    }
    .accomodationWrapper h3 {
        font-size: 18px;
        line-height: 30px;
        padding: 10px 0 10px 0;
    }
}

@media only screen and (min-width: 0px) and (max-width: 599px) {
    .adsSlideshowItem {
        min-height: 110px;
    }
    .advertisementImage {
        min-height: 110px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    header .headerInner {
        max-height: 66px;
    }
    header .headerInner .logo {
        max-width: 135px;
    }
    header .headerInner .navigation {
        padding-top: 21px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    header .headerInner .logo {
        max-width: 165px;
    }
    header .headerInner .navigation ul li {
        margin: 0 10px;
        font-size: 17px;
    }
    .carousel .slider-wrapper ul.slider .slide .legend h1 {
        font-size: 31px !important;
        line-height: 40px !important;
    }
    .carousel .slider-wrapper ul.slider .slide .legend p {
        font-size: 18px !important;
        line-height: 26px !important;
    }
    .notFoundWrapper .notFountData .notFoundImg img {
        max-width: 250px;
    }
    .adsSlideshowItem {
        min-height: 165px;
    }
    .advertisementImage {
        min-height: 165px;
    }
}

.dummyHeroBanner {
    img {
        max-width: 100%;
    }
}

.eventsWrapper {
    padding: 50px 0;
    button {
        &.tabMenu {
            background: #afafaf;
            font-weight: 900;
            font-size: 32px;
            line-height: 50px;
            text-transform: uppercase;
            color: #fff;
            margin-bottom: 19px;
            position: relative;
            padding-left: 31px;
            padding-right: 31px;
            align-items: flex-start;
            &.Mui-selected {
                background: #660066;
                box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.4);
                overflow: visible;
                &:after {
                    border-top: 16px solid transparent;
                    border-bottom: 16px solid transparent;
                    border-left: 16px solid #660066;
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: -16px;
                }
            }
        }
    }
    table {
        tbody {
            tr {
                td {
                    vertical-align: top;
                    border-color: #e3e3e3 !important;
                    &.dateCol {
                        min-width: 130px;
                        h3 {
                            font-weight: 700;
                            font-size: 24px;
                            line-height: 50px;
                            text-transform: capitalize;
                            color: #4ab7da;
                            white-space: nowrap;
                        }
                    }
                    &.eventCol {
                        width: calc(100% - 28%);
                        h2 {
                            font-weight: 700;
                            font-size: 32px;
                            line-height: 50px;
                            text-transform: capitalize;
                            color: #000;
                            margin: 0;
                            padding: 0;
                        }
                        span {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 20px;
                            color: #737171;
                        }
                    }
                    &.actionCol {
                        width: 10%;
                        button {
                            background: #660066;
                            border-radius: 50px;
                            font-weight: 900;
                            font-size: 16px;
                            text-transform: uppercase;
                            color: #fff;
                            padding: 5px 20px;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }
}
.MuiTabs-indicator {
    display: none;
}
.tabPanelWrapper {
    .MuiBox-root {
        padding: 0;
    }
}

.ctaCardWrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 50px -15px;
    .coxOuter {
        width: calc(25% - 30px);
        margin: 0 15px;
        display: inline-flex;
        align-items: flex-start;
        flex-wrap: wrap;
        min-height: 100%;
        -webkit-transition: all 0.4s ease-in-out;
        -moz-transition: all 0.4s ease-in-out;
        -o-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 30px 0 90px 0;
        position: relative;
        img {
            margin: 0 auto;
            display: inherit;
        }
        h2 {
            font-weight: 900;
            font-size: 32px;
            line-height: 50px;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.7);
            margin: 0;
            padding: 40px 25px 5px 25px;
            font-family: "Mulish", sans-serif;
        }
        p {
            font-weight: 400;
            font-size: 20px;
            line-height: 25px;
            color: #737171;
            margin: 0;
            padding: 0 25px 29px 25px;
            font-family: "Mulish", sans-serif;
        }
        &:hover {
            background: #580058;
            h2,
            p {
                color: #fff;
            }
            img {
                filter: brightness(0) invert(1);
            }
        }
        a {
            background: #580058;
            border-radius: 50px;
            border: 1px solid #580058;
            padding: 11px 22px;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            text-transform: uppercase;
            color: #fff;
            font-family: "Mulish", sans-serif;
        }
        &:hover {
            a {
                border-color: #fff;
            }
        }
        .ctaCardContent {
            padding: 0;
        }
        .cardActions {
            padding: 0 25px;
            position: absolute;
            bottom: 45px;
        }
    }
}
.containerFe {
    max-width: 1470px !important;
    padding: 0 15px;
}

.completionGuidlinesWrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    padding: 100px 0;
    .completionGuildContent {
        background-color: rgb(102 0 102 / 24%);
        border-radius: 0.25rem;
        padding: 50px 100px;
        border: 1px solid #b65eb6;
        color: #606;
    }
}

@media only screen and (min-width: 0px) and (max-width: 575px) {
    .ctaCardWrapper {
        margin: 20px 0 0 0;
    }
    .coxOuter {
        width: 100% !important;
        margin: 30px 0 0 0 !important;
    }
    .ctaCardWrapper .coxOuter .cardActions {
        bottom: 30px;
    }
    .eventsWrapper {
        padding: 0;
    }
    footer {
        margin-top: 50px;
    }
    footer .getItTouch address ul li h4 {
        line-height: 26px;
    }
}
.MuiTabs-indicator {
    display: none;
}
.tabPanelWrapper {
    .MuiBox-root {
        padding: 0;
    }
}

.carousel {
    height: 570px;
    .bannerSlideWrapper {
        height: 100% !important;
    }
    .slider-wrapper {
        height: 100% !important;
        ul {
            &.slider {
                height: 100% !important;
                .slide {
                    .bannerSlide {
                        height: 100%;
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: cover;
                    }
                    .legend {
                        opacity: 1 !important;
                        background: none !important;
                        bottom: 70px;
                        h1 {
                            font-weight: 900;
                            font-size: 50px;
                            line-height: 50px;
                            color: #fff;
                            margin: 0;
                            padding: 0 0 26px 0;
                            font-family: "Mulish", sans-serif;
                        }
                        p {
                            font-weight: 400;
                            font-size: 24px;
                            line-height: 30px;
                            color: #fff;
                            margin: 0;
                            padding: 0;
                            font-family: "Mulish", sans-serif;
                        }
                    }
                }
            }
        }
    }
    .control-dots {
        .dot {
            width: 33px !important;
            height: 7px !important;
            border-radius: 5px !important;
            box-shadow: none !important;
            opacity: 1 !important;
            margin: 0 4px !important;
            background: #660066 !important;
            &.selected {
                width: 22px !important;
                background: #cecece !important;
            }
        }
    }
}

.joinOurTeamWrapper {
    padding: 0 0 100px 0;
    h1 {
        font-weight: 700;
        font-size: 32px;
        line-height: 50px;
        text-transform: uppercase;
        color: #000;
        margin: 0;
        padding: 45px 0 0 15px;
    }
    p {
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        color: #737171;
        margin: 0;
        padding: 10px 0 6px 15px;
        span {
            font-weight: 700;
            color: #804b80;
            padding-left: 5px;
        }
        a {
            font-weight: 700;
            &:hover {
                color: #804b80;
            }
        }
    }
    .joinOurTeamForm {
        padding: 30px 0 0 15px;
        max-width: 80%;
        .topSpace {
            padding-top: 24px;
        }
        .topBtnSpace {
            padding-top: 40px;
        }
        label {
            &.inputLabel {
                padding-top: 0 !important;
                font-family: "Mulish", sans-serif !important;
                font-weight: 400 !important;
                font-size: 16px !important;
                text-transform: capitalize !important;
                color: #919191 !important;
            }
        }
        button {
            border-radius: 50px;
            font-weight: 700;
            font-size: 20px;
            line-height: 25px;
            text-transform: uppercase;
            padding: 10px 40px;
        }
        textarea {
            &#message {
                border-radius: 4px;
                position: relative;
                background-color: #fff;
                border: 1px solid #dfdfdf;
                font-size: 16px;
                width: auto;
                height: 100px !important;
                padding: 10px 12px;
                -webkit-transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                    background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                    background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                font-family: "Mulish", sans-serif !important;
                resize: none;
                margin-top: 24px;
                &:focus {
                    outline: none;
                    border: 1px solid #e8e8e8;
                    box-shadow: 0px 4px 20px rgb(128 75 128 / 20%);
                }
            }
        }
    }
}
img {
    &.responsiveImg {
        max-width: 100%;
    }
}
.bootstrapFields {
    width: 100%;
    input {
        width: 100% !important;
    }
}
.joinOurTeamWrapper {
    .carousel {
        .control-dots {
            text-align: right;
            padding-right: 30px;
        }
    }
}
p {
    &.Mui-error {
        font-weight: 400 !important;
        font-size: 0.75rem !important;
        line-height: 1.66 !important;
        letter-spacing: 0.03333em !important;
        text-align: left !important;
        padding-left: 0 !important;
        font-family: "Mulish", sans-serif !important;
        color: rgb(211, 47, 47) !important;
        padding-top: 5px !important;
        padding-bottom: 0;
    }
}
// .Mui-error {
//     input {
//         border-color: rgb(211, 47, 47);
//     }
// }
.eventHead {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 10px;
    h2 {
        display: inline-flex;
        align-items: center;
        flex-wrap: wrap;
        font-weight: 900;
        font-size: 32px;
        line-height: 50px;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.7);
        margin: 0;
        padding: 0;
    }
    a {
        display: inline-flex;
        align-items: center;
        flex-wrap: wrap;
        font-weight: 300;
        font-size: 20px;
        line-height: 50px;
        text-decoration-line: underline;
        text-transform: uppercase;
        color: #660066;
    }
}
.tabsWrapper {
    padding-top: 60px;
}
.eventsBox {
    padding-left: 100px;
}
.tabPanelWrapper {
    width: calc(100% - 400px);
}
.fluidContainer {
    max-width: 100% !important;
    &.padLeftNone {
        padding-left: 0px;
    }
}
.ycadaBannerWrapper {
    background: #580058;
    margin-top: 45px;
    margin-left: 18px;
    padding: 25px 30px;
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
            display: inline-flex;
            align-items: center;
            flex-wrap: wrap;
            font-family: "Mulish", sans-serif !important;
            font-weight: 300;
            font-size: 28px;
            line-height: 30px;
            text-transform: uppercase;
            color: #fff;
            width: 50%;
            span {
                display: flex;
                width: 100%;
                b {
                    font-weight: 700;
                    padding-left: 10px;
                }
            }
            button {
                background: #fff;
                border: 1px solid #fff;
                border-radius: 50px;
                font-weight: 700;
                font-size: 20px;
                line-height: 25px;
                text-transform: uppercase;
                color: #580058;
                padding: 18px 50px;
                &:hover {
                    background: #580058;
                    color: #fff;
                }
            }
        }
    }
}

.eventDetailsWrapper {
    margin: 0;
    padding: 48px 0;
    .eventDetailsContent {
        .eventDetails {
            .eventDetailsImg {
                img {
                    max-width: 100%;
                }
            }
            .eventDetailsTopContent {
                h3 {
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 50px;
                    text-transform: uppercase;
                    color: #000;
                    margin: 0;
                    padding: 0 0 11px 0;
                }
                h4 {
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 25px;
                    color: #660066;
                    margin: 0;
                    padding: 0;
                }
                ul {
                    margin: 0;
                    padding: 12px 0 0 0;
                    list-style: none;
                    li {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 28px;
                        text-transform: capitalize;
                        font-family: "Mulish", sans-serif !important;
                        color: #919191;
                    }
                }
            }
            .tabLinks {
                &.conferencesDetail {
                    padding-top: 20px;
                }
                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    li {
                        display: inline-flex;
                        align-items: center;
                        flex-wrap: wrap;
                        margin-right: 30px;
                        margin-bottom: 15px;
                        button {
                            border-radius: 50px;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 23px;
                            text-transform: uppercase;
                            color: #fff;
                            padding: 18px 31px;
                            &.blackButton {
                                background-color: #000;
                            }
                            &.greenButton {
                                background-color: #50bc2a;
                            }
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
            p {
                &.eventContent {
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 25px;
                    color: #000;
                    span {
                        color: #660066;
                        cursor: pointer;
                    }
                }
            }
            .boxContentWrapper {
                background: #fff;
                border: 1px solid #d8d8d8;
                height: 100%;
                &.conferencesDetail {
                    height: auto;
                }
                .boxHeading {
                    margin: 0;
                    padding: 10px 20px;
                    background: #580058;
                    border-bottom: 1px solid #d8d8d8;
                    h4 {
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 25px;
                        text-transform: uppercase;
                        color: #fff;
                        margin: 0;
                        padding: 0;
                    }
                    p {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 25px;
                        color: #fff;
                        margin: 0;
                        padding: 0;
                    }
                }
                .boxContent {
                    margin: 0;
                    padding: 18px 20px 35px 20px;
                    ul {
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        li {
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 25px;
                            color: #000;
                            font-family: "Mulish", sans-serif;
                            &.label {
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 25px;
                                color: #000;
                            }
                        }
                    }
                    .boxSpacer {
                        width: 100%;
                        height: 30px;
                        &.hide {
                            display: none;
                        }
                    }
                    p {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 25px;
                        color: #000;
                        word-break: break-all;
                    }
                    h5 {
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 25px;
                        color: #ff0000;
                    }
                }
            }
            .listingContent {
                margin: 0;
                padding: 0;
                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    li {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 25px;
                        color: #000;
                        position: relative;
                        padding-left: 15px;
                        strong {
                            font-weight: 700;
                        }
                        span {
                            font-weight: 700;
                            color: #660066;
                        }
                        &:before {
                            width: 6px;
                            height: 6px;
                            left: 0;
                            top: 11px;
                            position: absolute;
                            content: "";
                            border-radius: 100%;
                            background: #000;
                        }
                    }
                }
            }
            .googleMap {
                overflow: hidden;
                border: 8px solid #fff;
                filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.15));
                iframe {
                    width: 100%;
                    height: 350px;
                    border: none;
                }
            }
            .eventAddress {
                &.conferencesDetail {
                    padding-top: 40px;
                }
                h3 {
                    margin: 0;
                    padding: 0;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 25px;
                    text-transform: uppercase;
                    color: #000;
                }
                p {
                    margin: 0;
                    padding: 0 0 10px 0;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 25px;
                    color: #000;
                }
                ul {
                    margin: 0;
                    padding: 0 0 16px 0;
                    list-style: none;
                    width: 100%;
                    li {
                        button {
                            width: 100%;
                            border-radius: 50px;
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 23px;
                            text-transform: uppercase;
                            color: #fff;
                            padding: 10px 0;
                            &.blackButton {
                                background: #050505;
                            }
                        }
                    }
                    &.halfButton {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        margin: 0 -8px;
                        li {
                            display: inline-flex;
                            align-items: center;
                            flex-wrap: wrap;
                            padding: 0 8px;
                            width: calc(50% - 18px);
                        }
                    }
                }
            }
        }
    }
}

.conferencesDetailInner {
    h2 {
        font-size: 28px;
        font-weight: 700;
        text-align: center;
        color: #580058;
        margin: 20px 0 0 0;
        padding: 0;
    }
}

.aboutWrapper {
    width: 100%;
    max-width: 700px;
    .rulesContent {
        margin: 0;
        padding: 0 0 0 15px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-family: "Mulish", sans-serif;
    }
    p {
        font-size: 16px;
        line-height: 25px;
        color: #737171;
        a {
            color: #737171;
            &:hover {
                color: #804b80;
            }
        }
    }
}
.rulesAndQuestionsWrapper {
    width: 100%;
    max-width: 700px;
    .rulesContent {
        margin: 0;
        padding: 15px 0 15px 15px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-family: "Mulish", sans-serif;
    }
}
.competitionGuildWrapper {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 700px;
    ul {
        margin: 0;
        padding: 15px 0 15px 15px;
        list-style: none;
        li {
            font-size: 16px;
            line-height: 25px;
            color: #737171;
            position: relative;
            padding-left: 20px;
            font-family: "Mulish", sans-serif;
            a {
                color: #737171;
                &:hover {
                    color: #804b80;
                    font-weight: 700;
                }
            }
            &:before {
                top: 11px;
                left: 2px;
                width: 7px;
                height: 7px;
                border-radius: 100%;
                background: #000;
                position: absolute;
                content: "";
            }
        }
    }
    .rulesContent {
        margin: 0;
        padding: 15px 0 15px 15px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-family: "Mulish", sans-serif;
        h2 {
            font-weight: 700;
            margin-right: 30px;
        }
        button {
            background: #580058;
            border-radius: 50px;
            padding: 11px 22px;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            text-transform: capitalize;
            color: #fff;
        }
    }
    p {
        font-size: 16px;
        line-height: 25px;
        color: #737171;
    }
}
.carousel {
    .slide {
        .legend {
            bottom: 60px;
        }
    }
}
.privacyPolicyWrapper {
    margin: 0;
    padding: 2rem 0 3rem 0;

    h1 {
        font-weight: 700;
        font-size: 32px;
        line-height: 50px;
        text-transform: uppercase;
        color: #000;
        margin: 0;
        padding: 0;
    }
    h2 {
        font-weight: 500;
        font-size: 28px;
        line-height: 35px;
        color: #000;
        margin: 0;
        padding: 15px 0 0 0;
    }
}

@media only screen and (min-width: 0px) and (max-width: 575px) {
    .carousel {
        height: 400px;
    }
    .carousel .slider-wrapper ul.slider .slide .legend h1 {
        font-weight: 900;
        font-size: 21px;
        line-height: 28px;
        padding: 0 0 10px 0;
    }
    .carousel .slider-wrapper ul.slider .slide .legend p {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
    }
    .eventsWrapper .MuiTabs-flexContainer {
        flex-direction: row !important;
        padding-bottom: 20px;
    }
    .eventsWrapper .MuiTabs-flexContainer button {
        font-weight: 400;
        font-size: 21px;
        line-height: 31px;
        margin-bottom: 19px;
        padding-left: 18px;
        padding-right: 18px;
        width: auto;
        margin-right: 10px;
        text-transform: capitalize;
    }
    .eventsWrapper button.tabMenu.Mui-selected:after {
        right: auto;
        left: 50%;
        bottom: -31px;
        top: auto;
        transform: translateX(-50%);
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
        border-top: 16px solid #660066;
    }
    .tabsWrapper {
        min-width: 100% !important;
    }
    .eventsWrapper .MuiBox-root {
        flex-wrap: wrap;
    }
    .tabPanelWrapper {
        width: 100%;
        overflow-x: auto;
    }
    .tabContentTable table tbody tr {
        display: table-row;
    }
    .eventsWrapper table tbody tr td {
        display: block;
        border-bottom: none;
    }
    .eventsWrapper table tbody tr td.dateCol h3 {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        padding-top: 16px;
        padding-bottom: 0;
    }
    .eventsWrapper table tbody tr td.actionCol {
        padding-top: 0 !important;
        border-bottom: 1px solid #000;
    }
    .eventsWrapper table tbody tr td.eventCol h2 {
        font-weight: 700;
        font-size: 22px;
        line-height: 32px;
        padding: 0 0 5px 0;
    }
    .eventsBox {
        padding-left: 0;
    }
    .eventHead h2 {
        padding-right: 10px;
    }
    header.topHeader {
        position: relative;
        background: rgba(0, 0, 0, 1);
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .ctaCardWrapper {
        margin: 20px 0;
    }
    .coxOuter {
        width: calc(50% - 30px) !important;
        margin: 30px 15px !important;
        padding: 30px 0 90px 0 !important;
    }
    .ctaCardWrapper .coxOuter .cardActions {
        bottom: 30px;
    }
    .ctaCardContent img {
        max-width: 90px;
    }
    .ctaCardWrapper .coxOuter h2 {
        font-size: 28px;
        line-height: 30px;
        padding: 20px 25px 5px 25px;
    }
    .ctaCardWrapper .coxOuter p {
        font-size: 17px;
        line-height: 25px;
        padding: 0 25px 0px 25px;
    }
    .ctaCardWrapper .coxOuter button {
        margin-top: 15px;
    }
    .carousel {
        height: 420px;
    }
    .carousel .slider-wrapper ul.slider .slide .legend h1 {
        font-weight: 900;
        font-size: 32px;
        line-height: 35px;
        padding: 0 0 10px 0;
    }
    .carousel .slider-wrapper ul.slider .slide .legend p {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
    }
    .eventsWrapper .MuiTabs-flexContainer {
        flex-direction: row !important;
        padding-bottom: 20px;
    }
    .eventsWrapper .MuiTabs-flexContainer button {
        font-weight: 400;
        font-size: 25px;
        line-height: 35px;
        margin-bottom: 19px;
        padding-left: 18px;
        padding-right: 18px;
        width: auto;
        margin-right: 10px;
        text-transform: uppercase;
    }
    .eventsWrapper button.tabMenu.Mui-selected:after {
        right: auto;
        left: 50%;
        bottom: -31px;
        top: auto;
        transform: translateX(-50%);
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
        border-top: 16px solid #660066;
    }
    .tabsWrapper {
        min-width: 100% !important;
    }
    .eventsWrapper .MuiBox-root {
        flex-wrap: wrap;
    }
    .tabPanelWrapper {
        width: 100%;
        overflow-x: auto;
    }
    .eventsWrapper table tbody tr td.dateCol h3 {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
    }
    .eventsWrapper table tbody tr td.eventCol h2 {
        font-weight: 700;
        font-size: 22px;
        line-height: 32px;
        padding: 0 0 5px 0;
    }
    .eventsBox {
        padding-left: 0;
    }
    header.topHeader {
        position: relative;
        background: rgba(0, 0, 0, 1);
    }
    .notFoundWrapper .notFountData {
        padding: 50px 15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .ctaCardWrapper {
        margin: 20px 0;
    }
    .coxOuter {
        width: calc(50% - 30px) !important;
        margin: 30px 15px !important;
        padding: 30px 0 75px 0 !important;
    }
    .ctaCardWrapper .coxOuter .cardActions {
        bottom: 30px;
    }
    .eventsBox {
        padding-left: 0;
    }
    .eventsWrapper button.tabMenu {
        max-width: 280px;
        font-size: 26px;
        line-height: 36px;
    }
    .tabsWrapper {
        min-width: 310px !important;
    }
    .tabPanelWrapper {
        width: 100%;
        overflow: hidden;
    }
    .tabContentTable {
        width: 100%;
        overflow-x: auto;
    }
    .eventsWrapper table {
        min-width: 540px;
    }
    .eventsWrapper table tbody tr td.dateCol h3 {
        font-size: 19px;
        line-height: 25px;
    }
    .eventsWrapper table tbody tr td.eventCol h2 {
        font-size: 25px;
        line-height: 29px;
        padding: 0 0 5px 0;
    }
    .eventsWrapper table tbody tr td {
        display: block;
        border-bottom: none;
    }
    .eventsWrapper table tbody tr td.dateCol h3 {
        padding-top: 16px;
        padding-bottom: 0;
    }
    .eventsWrapper table tbody tr td.actionCol {
        padding-top: 0 !important;
        border-bottom: 1px solid #000;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .ctaCardWrapper {
        margin: 20px 0;
    }
    .coxOuter {
        width: calc(33.3334% - 30px) !important;
        margin: 30px 15px !important;
        padding: 30px 0 60px 0 !important;
    }
    .ctaCardWrapper .coxOuter .cardActions {
        bottom: 30px;
    }
    .eventsBox {
        padding-left: 0;
    }
    .tabPanelWrapper {
        width: calc(100% - 280px);
    }
    .notFoundWrapper .notFountData .notFoundImg img {
        max-width: 280px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .ctaCardWrapper {
        margin: 20px 0;
    }
    .coxOuter {
        margin: 30px 15px !important;
        padding: 30px 0 70px 0 !important;
    }
    .ctaCardWrapper .coxOuter .cardActions {
        bottom: 25px;
    }
    .ctaCardContent img {
        max-width: 100px;
    }
    .ctaCardWrapper .coxOuter h2 {
        font-size: 27px;
        line-height: 38px;
        padding: 30px 25px 5px 25px;
    }
    .ctaCardWrapper .coxOuter p {
        font-size: 18px;
        line-height: 24px;
        padding: 0 25px 20px 25px;
    }
    .carousel {
        height: 450px;
    }
    .carousel .slider-wrapper ul.slider .slide .legend h1 {
        font-weight: 900;
        font-size: 35px;
        line-height: 40px;
        padding: 0 0 15px 0;
    }
    .carousel .slider-wrapper ul.slider .slide .legend p {
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
    }
    .eventsWrapper button.tabMenu {
        background: #afafaf;
        font-weight: 500;
        font-size: 24px;
        line-height: 35px;
        margin-right: 20px;
    }
    .eventsWrapper table tbody tr td.dateCol h3 {
        font-size: 20px;
        line-height: 45px;
    }
    .eventsWrapper table tbody tr td.eventCol h2 {
        font-size: 26px;
        line-height: 48px;
    }
    .tabPanelWrapper {
        width: calc(100% - 300px);
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .carousel {
        height: 500px;
    }
    .carousel .slider-wrapper ul.slider .slide .legend h1 {
        font-weight: 900;
        font-size: 38px;
        line-height: 42px;
        padding: 0 0 15px 0;
    }
    .carousel .slider-wrapper ul.slider .slide .legend p {
        font-weight: 400;
        font-size: 21px;
        line-height: 28px;
    }

    .tabsWrapper {
        min-width: 280px !important;
    }
    .eventsWrapper button.tabMenu {
        font-weight: 500;
        font-size: 24px;
        line-height: 38px;
        padding-left: 20px;
        padding-right: 20px;
        width: 245px;
    }
    .eventsWrapper table tbody tr td.dateCol h3 {
        font-weight: 500;
        font-size: 20px;
    }
    .eventsWrapper table tbody tr td.eventCol h2 {
        font-weight: 700;
        font-size: 25px;
    }
    .eventsWrapper table tbody tr td.actionCol button {
        margin-top: 10px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .tabsWrapper {
        min-width: 300px !important;
    }
    .eventsWrapper button.tabMenu {
        font-weight: 700;
        font-size: 25px;
        line-height: 40px;
        padding-left: 25px;
        padding-right: 25px;
        width: 270px;
    }
    .eventsWrapper table tbody tr td.dateCol h3 {
        font-weight: 700;
        font-size: 19px;
        line-height: 32px;
    }
    .eventsWrapper table tbody tr td.eventCol h2 {
        font-size: 27px;
        line-height: 36px;
        padding: 0 0 5px 0;
    }
    .eventsWrapper table tbody tr td.actionCol button {
        margin-top: 5px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 374px) {
    header .headerInner .logo {
        max-width: 105px;
        margin-top: 10px;
    }
    header .headerInner .navigation {
        padding-top: 30px;
    }
    .eventsWrapper button.tabMenu {
        padding: 5px 10px;
        line-height: 30px;
        font-size: 18px;
    }
    .eventHead h2 {
        font-size: 26px;
        line-height: 27px;
        padding-right: 10px;
    }
    .eventHead a {
        font-size: 16px;
        line-height: 36px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 529px) {
    .eventDetailsWrapper .eventDetailsContent .eventDetails .tabLinks ul li {
        width: 100%;
        margin-right: 0;
    }
    .eventDetailsWrapper .eventDetailsContent .eventDetails .tabLinks ul li button {
        font-size: 15px;
        line-height: 22px;
        padding: 12px 20px;
        width: 100%;
    }
    .eventDetailsWrapper .eventDetailsContent .eventDetails p.eventContent {
        font-size: 17px;
    }
    .eventDetailsWrapper .eventDetailsContent .eventDetails .boxContentWrapper .boxHeading {
        padding: 10px 15px;
    }
    .eventDetailsWrapper .eventDetailsContent .eventDetails .boxContentWrapper .boxContent {
        padding: 15px;
    }
    .eventDetailsWrapper .eventDetailsContent .eventDetails .boxContentWrapper .boxContent ul li {
        font-size: 18px;
    }
    .eventDetailsWrapper .eventDetailsContent .eventDetails .eventAddress ul.halfButton {
        width: calc(100% + 16px);
    }
}

@media only screen and (min-width: 530px) and (max-width: 599px) {
    .eventDetailsWrapper .eventDetailsContent .eventDetails .tabLinks ul {
        margin: 0 -10px;
    }
    .eventDetailsWrapper .eventDetailsContent .eventDetails .tabLinks ul li {
        margin-right: 0;
        width: calc(50% - 20px);
        margin-bottom: 10px;
        padding: 0 10px;
    }
    .eventDetailsWrapper .eventDetailsContent .eventDetails .tabLinks ul li:first-child {
        width: 100%;
    }
    .eventDetailsWrapper .eventDetailsContent .eventDetails .tabLinks ul li button {
        font-size: 15px;
        line-height: 22px;
        padding: 12px 20px;
        width: 100%;
    }
    .eventDetailsWrapper .eventDetailsContent .eventDetails p.eventContent {
        font-size: 17px;
    }
    .eventDetailsWrapper .eventDetailsContent .eventDetails .boxContentWrapper .boxHeading {
        padding: 10px 15px;
    }
    .eventDetailsWrapper .eventDetailsContent .eventDetails .boxContentWrapper .boxContent {
        padding: 15px;
    }
    .eventDetailsWrapper .eventDetailsContent .eventDetails .boxContentWrapper .boxContent ul li {
        font-size: 18px;
    }
    .eventDetailsWrapper .eventDetailsContent .eventDetails .eventAddress ul.halfButton {
        width: calc(100% + 16px);
    }
}

@media only screen and (min-width: 0px) and (max-width: 599px) {
    .joinOurTeamWrapper .joinOurTeamForm {
        max-width: 100% !important;
    }
    .joinOurTeamCarousel {
        padding-left: 16px;
    }
    .contactWrapper .contactFormWraper {
        padding-right: 0;
    }
    .contactWrapper img.contactImg {
        max-width: calc(100% - 15px);
        padding-left: 15px;
    }
    .contactWrapper h1 {
        padding: 0;
    }
    .contactWrapper .selectWrapper {
        margin-left: 0;
        margin-top: 10px;
    }
    .contactWrapper .ruleQuestion {
        padding: 20px 0 0 15px;
    }
    .contactWrapper .contactRight {
        padding: 30px 0 0 15px;
        max-width: 280px;
    }
    .eventDetailsWrapper .eventDetailsContent .eventDetails .eventDetailsTopContent h3 {
        font-size: 26px;
        line-height: 35px;
    }
    .eventDetailsWrapper .eventDetailsContent .eventDetails .eventDetailsTopContent h4 {
        font-size: 16px;
        line-height: 23px;
    }
}

@media only screen and (min-width: 599px) and (max-width: 767px) {
    .joinOurTeamWrapper .joinOurTeamForm {
        max-width: 100% !important;
    }
    .joinOurTeamCarousel {
        padding-left: 24px;
    }
    .joinOurTeamWrapper h1 {
        padding: 10px 0 0 15px;
    }
    .contactWrapper .contactFormWraper {
        padding-left: 24px;
        padding-right: 0;
    }
    .contactWrapper img.contactImg {
        max-width: calc(100% - 24px);
        padding-left: 24px;
    }
    .contactWrapper h1 {
        padding: 0;
    }
    .contactWrapper .contactformSelect span.selectWrapper {
        margin-left: 0;
        margin-top: 10px;
    }
    .contactWrapper .ruleQuestion {
        padding: 20px 0 0 24px;
    }
    .contactWrapper .contactRight {
        padding: 30px 0 0 24px;
        max-width: 280px;
    }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
    .contactWrapper h1 {
        font-size: 25px;
        line-height: 30px;
        padding: 20px 0 0 0;
    }
    .contactWrapper .contactformSelect {
        font-size: 18px;
    }
    .contactWrapper .contactformSelect span.selectWrapper {
        margin-top: 8px;
        margin-left: 0;
    }
    .contactWrapper .contactFormWraper {
        padding-right: 15px;
    }
    .contactWrapper .ruleQuestion {
        padding: 20px 0 0 0;
    }
    .contactWrapper .ruleQuestion h3 {
        font-size: 21px;
        line-height: 30px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 480px) {
    .ycadaBannerWrapper {
        padding: 15px;
    }
    .ycadaBannerWrapper ul li {
        width: 100%;
        font-size: 21px;
        line-height: 26px;
    }
    .ycadaBannerWrapper ul li button {
        font-size: 15px;
        line-height: 25px;
        padding: 10px 15px;
        margin-top: 15px;
    }
}

@media only screen and (min-width: 481px) and (max-width: 575px) {
    .ycadaBannerWrapper {
        padding: 25px;
    }
    .ycadaBannerWrapper ul li {
        width: 100%;
        font-size: 26px;
        line-height: 33px;
    }
    .ycadaBannerWrapper ul li button {
        font-size: 18px;
        line-height: 28px;
        padding: 12px 30px;
        margin-top: 20px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 700px) {
    .ycadaBannerWrapper {
        padding: 30px;
    }
    .ycadaBannerWrapper ul li {
        width: 100%;
    }
    .ycadaBannerWrapper ul li button {
        margin-top: 20px;
    }
}

@media only screen and (min-width: 701px) and (max-width: 899px) {
    .ycadaBannerWrapper {
        padding: 20px;
    }
    .ycadaBannerWrapper ul li {
        font-size: 24px;
        line-height: 30px;
    }
    .ycadaBannerWrapper ul li button {
        font-size: 18px;
        line-height: 28px;
        padding: 12px 15px;
    }
    .ycadaBannerWrapper ul li:last-child {
        justify-content: flex-end;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .ycadaBannerWrapper {
        padding: 25px;
    }
    .ycadaBannerWrapper ul li {
        font-size: 26px;
        line-height: 33px;
    }
    .ycadaBannerWrapper ul li button {
        font-size: 18px;
        line-height: 28px;
        padding: 12px 22px;
    }
    .ycadaBannerWrapper ul li:last-child {
        justify-content: flex-end;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1499px) {
    .ycadaBannerWrapper {
        padding: 25px;
    }
    .ycadaBannerWrapper ul li {
        font-size: 26px;
        line-height: 33px;
    }
    .ycadaBannerWrapper ul li button {
        font-size: 18px;
        line-height: 28px;
        padding: 12px 22px;
    }
    .ycadaBannerWrapper ul li:last-child {
        justify-content: flex-end;
    }
}

@media (max-width: 1199px) {
    .contactWrapper .contactformSelect span.text {
        margin-bottom: 1.5rem;
    }
}

@media (max-width: 899px) {
    .contactWrapper .contactformSelect span.selectWrapper {
        margin-left: 0;
    }

    .contactImageWrapper {
        text-align: center;
        margin: 1rem 0 0 !important;
        img {
            padding-left: 0;
        }
    }
}

@media only screen and (min-width: 600px) and (max-width: 899px) {
    .eventDetailsWrapper .eventDetailsContent .eventDetails .eventDetailsTopContent h3 {
        font-size: 27px;
        line-height: 26px;
    }
    .eventDetailsWrapper .eventDetailsContent .eventDetails .eventDetailsTopContent h4 {
        font-size: 18px;
    }
    .eventDetailsWrapper .eventDetailsContent .eventDetails .tabLinks ul {
        margin: 0 -20px;
        width: calc(100% + 40px);
    }
    .eventDetailsWrapper .eventDetailsContent .eventDetails .tabLinks ul li:first-child {
        width: 100%;
    }
    .eventDetailsWrapper .eventDetailsContent .eventDetails .tabLinks ul li {
        padding: 0 20px;
        width: calc(50% - 40px);
        margin-right: 0;
        margin-bottom: 15px;
    }
    .eventDetailsWrapper .eventDetailsContent .eventDetails .tabLinks ul li button {
        width: 100%;
        padding: 18px;
    }
    .eventDetailsWrapper .eventDetailsContent .eventDetails .boxContentWrapper .boxHeading {
        padding: 10px 15px;
    }
    .eventDetailsWrapper .eventDetailsContent .eventDetails .boxContentWrapper .boxContent {
        padding: 15px;
    }
    .eventDetailsWrapper .eventDetailsContent .eventDetails .boxContentWrapper .boxContent ul li {
        font-size: 18px;
    }
    .eventDetailsWrapper .eventDetailsContent .eventDetails .eventAddress ul.halfButton {
        width: calc(100% + 16px);
    }
    .eventDetailsWrapper .eventDetailsContent .eventDetails .googleMap iframe {
        height: 280px;
    }
    .eventDetailsWrapper .eventDetailsContent .eventDetails .eventAddress ul li button {
        font-size: 16px;
    }
    .joinOurTeamCarousel {
        padding-left: 24px;
    }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
    footer hr {
        margin: 20px 0 0 0;
    }
    footer ul.socialLinks {
        justify-content: flex-end;
    }
    .eventDetailsWrapper .eventDetailsContent .eventDetails .tabLinks ul li {
        margin-right: 20px;
        margin-bottom: 15px;
    }
    .eventDetailsWrapper .eventDetailsContent .eventDetails .tabLinks ul li button {
        font-size: 16px;
        line-height: 20px;
        padding: 15px 20px;
    }
}

@media only screen and (min-width: 374px) and (max-width: 767px) {
    header.topHeader {
        min-height: 61px;
    }
    header .headerInner .navigation {
        padding-top: 19px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 380px) {
    .rulesContent h2 {
        width: 100%;
        padding-bottom: 15px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 1399px) {
    .privacyPolicyWrapper {
        padding: 2rem 15px 3rem 15px;
    }
}

.ckeditor_content_render {
    padding: 1rem 0 0;
    font-family: "Mulish", sans-serif;
    h2 {
        margin: 0 0 1rem;
        word-break: break-word;
    }
    h3 {
        margin: 0 0 0.5rem;
        word-break: break-word;
    }
    h4 {
        margin: 0 0 0.25rem;
        word-break: break-word;
    }
    p {
        padding: 0;
        margin: 0 0 1rem;
        word-break: break-word;
    }
    a {
        color: #580058;
        text-decoration: underline;
        word-break: break-word;
    }
    ul,
    ol {
        margin: 0;
        padding: 0 0 1rem 0;
        list-style: none;
        li {
            font-size: 16px;
            line-height: 25px;
            color: #737171;
            position: relative;
            padding-left: 20px;
            font-family: "Mulish", sans-serif;
            word-break: break-word;
            a {
                color: #737171;
                &:hover {
                    color: #804b80;
                    font-weight: 700;
                }
            }
            &:before {
                top: 11px;
                left: 2px;
                width: 7px;
                height: 7px;
                border-radius: 100%;
                background: #000;
                position: absolute;
                content: "";
            }
        }
    }
    ol {
        list-style-type: decimal !important;
        margin-left: 20px;
        li {
            padding-left: 0;
            &:before {
                display: none;
            }
        }
    }
}

.advertisement {
    margin: 64px 0 100px;

    .react-slideshow-container .default-nav {
        display: none;
    }
}

@media only screen and (min-width: 600px) and (max-width: 899px) {
    .tabLinks.conferencesDetail li.buttonWrapper {
        width: 100% !important;
    }
}

.awardsContent {
    ul {
        list-style: disc;
        padding: 2rem 0 1rem;
        margin: 0 0 0 1rem;
        li {
            padding: 0;
            margin: 0 0 1rem;
        }
    }
}
